


















import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import CounterpartyBankResponseModel from "@/accounting-portal/models/counterparties/CounterpartyBankResponseModel";
import { AppSection } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    ConfirmationDialog,
  },
})
export default class BanksTable extends mixins(AppSectionAccessMixin) {
  readonly HEADERS = [
    {
      text: this.$lang("accountingPortal.counterparties.banks.name"),
      value: "bankName",
    },
    {
      text: this.$lang("accountingPortal.counterparties.banks.dateFrom"),
      value: "dateFrom",
    },
    {
      text: this.$lang("accountingPortal.counterparties.banks.dateTo"),
      value: "dateTo",
    },
  ];

  get loading(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartyBanksLoading;
  }

  get counterpartyBanks(): Array<CounterpartyBankResponseModel> {
    return this.$store.state.counterpartyStore.counterpartyBanks;
  }

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }
}
