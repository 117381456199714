import CounterpartyBankResponseModel from "./CounterpartyBankResponseModel";

export default class CounterpartyBanksRequestModel {
  constructor(
    public banksByPeriod: Array<CounterpartyBankByPeriodModel> = [
      new CounterpartyBankByPeriodModel(),
    ],
    public counterpartyId?: number
  ) {}

  static ofRequest(
    model: CounterpartyBanksRequestModel
  ): CounterpartyBanksRequestModel {
    return new CounterpartyBanksRequestModel(
      CounterpartyBankByPeriodModel.ofRequest(model.banksByPeriod),
      model.counterpartyId
    );
  }
}

export class CounterpartyBankByPeriodModel {
  constructor(
    public bankId: number | null = null,
    public dateFrom: string | Date | null = null,
    public dateTo: string | Date | null = null,
    public isDateFromMenuActive?: boolean,
    public isDateToMenuActive?: boolean
  ) {}
  static of(
    model: CounterpartyBankByPeriodModel
  ): CounterpartyBankByPeriodModel {
    return new CounterpartyBankByPeriodModel(
      model.bankId,
      model.dateFrom,
      model.dateTo
    );
  }
  static ofArray(
    items: Array<CounterpartyBankByPeriodModel | CounterpartyBankResponseModel>
  ): Array<CounterpartyBankByPeriodModel> {
    return items.map((item) => CounterpartyBankByPeriodModel.of(item));
  }

  static ofRequest(
    items: Array<CounterpartyBankByPeriodModel>
  ): Array<CounterpartyBankByPeriodModel> {
    return items.map(
      (item) =>
        new CounterpartyBankByPeriodModel(
          item.bankId,
          item.dateFrom,
          item.dateTo
        )
    );
  }
}
