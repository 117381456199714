











































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

import EntityCard from "@/accounting-portal/components/counterparties/EntityCard.vue";
import BanksTable from "@/accounting-portal/components/counterparties/banks/BanksTable.vue";
import BanksForm from "@/accounting-portal/components/counterparties/banks/BanksForm.vue";
import CounterpartyBanksRequestModel, {
  CounterpartyBankByPeriodModel,
} from "@/accounting-portal/models/counterparties/CounterpartyBanksRequestModel";
import CounterpartyBankResponseModel from "@/accounting-portal/models/counterparties/CounterpartyBankResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    EntityCard,
    BanksTable,
    BanksForm,
  },
})
export default class BanksView extends mixins(AppSectionAccessMixin) {
  visible = false;
  localValue = new CounterpartyBanksRequestModel();

  get saving(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartyBanksSaving;
  }

  get counterpartyBanks(): Array<CounterpartyBankResponseModel> {
    return this.$store.state.counterpartyStore.counterpartyBanks;
  }

  get counterpartyId(): number {
    return Number(this.$route.params.counterpartyId);
  }

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }

  async created() {
    await Promise.all([
      this.$store.dispatch("loadCounterpartyBanks", this.counterpartyId),
      this.$store.dispatch("loadBanks"),
    ]);

    this.localValue.banksByPeriod = CounterpartyBankByPeriodModel.ofArray(
      this.counterpartyBanks
    );
  }

  handleChangeVisible(value: boolean) {
    this.visible = value;

    if (value && !this.counterpartyBanks.length) {
      this.localValue.banksByPeriod = [
        new CounterpartyBankByPeriodModel(null, "2018-09-01"),
      ];
    }
  }

  handleAddingPeriod() {
    this.localValue.banksByPeriod.push(new CounterpartyBankByPeriodModel());
  }

  async handleSaving() {
    if (this.counterpartyBanks.length) {
      await this.$store.dispatch("updateCounterpartyBanks", {
        counterpartyId: this.counterpartyId,
        payload: CounterpartyBanksRequestModel.ofRequest(this.localValue),
      });
    } else {
      this.localValue.counterpartyId = this.counterpartyId;
      await this.$store.dispatch(
        "createCounterpartyBanks",
        CounterpartyBanksRequestModel.ofRequest(this.localValue)
      );
    }

    this.handleChangeVisible(false);
  }

  handleDeletingAll() {
    this.$store.dispatch("deleteAllCounterpartyBanks", this.counterpartyId);
  }
}
